import { Locale, LOCALE } from '../constants/locale.constants'
import { rewriteTherapistCredentials } from './selectorHelpers'
import { TherapistType } from '../types/TherapistStateType'
import qs from 'qs'

// this populates the full localizations object, meaning it downloads all the content for all the languages at once
// it needs to be replaced by more pinpoint query
export const DEPRECATED_DEEP = 'deep'

export const API_URL = `${process.env.NEXT_PUBLIC_API_URL}${process.env.NEXT_PUBLIC_API_VERSION}`
export const STRAPI_RESPONSE_FORMAT = 'v4'

type CacheType = 'no-store' | 'force-cache'

export const processTherapistList = (therapists: TherapistType[]) => {
  return therapists.map((therapist) => rewriteTherapistCredentials(therapist))
}

export const fetchFromApi = async (route: string) => {
  const res = await fetch(`${API_URL}/${route}`)
  return res.json()
}

export const fetchFromStrapiRest = async (
  path: string,
  params: Record<string, any> = {},
  cache: CacheType = 'force-cache',
) => {
  const url = `${process.env.NEXT_PUBLIC_CMS_URL}/api/${path}?${qs.stringify({
    locale: LOCALE,
    pagination: { pageSize: 100 },
    ...params,
  })}`
  const res = await fetch(url, { cache, headers: { 'Strapi-Response-Format': STRAPI_RESPONSE_FORMAT } })
  const json = await res.json()
  return json.data
}

export const fetchBlendedCareLesson = async (slug: string) => {
  const res = await fetchFromStrapiRest('blended-care-lessons', {
    populate: {
      image: true,
      steps: {
        populate: {
          image: true,
          poll: { populate: ['image'] },
          questions: { populate: ['image'] },
          audio: true,
          video: true,
          bubbles: true,
          followup: true,
        },
      },
    },
    filters: { slug: { $eq: slug } },
  })
  return res[0] ? { ...res[0].attributes, id: res[0].id } : undefined
}
export const fetchBlendedCareCourse = async (slug: string) => {
  const res = await fetchFromStrapiRest('blended-care-courses', {
    populate: { blended_care_lessons: { populate: { image: true } }, image: true, expertPhoto: true },
    filters: { slug: { $eq: slug } },
  })
  return res[0] ? { ...res[0].attributes, id: res[0].id } : undefined
}
export const fetchAllBlendedCareCoursesFromStrapi = async () =>
  fetchFromStrapiRest('blended-care-courses', {
    populate: { blended_care_lessons: { populate: 'image' }, image: true },
  })

export const fetchAllArticlesFromStrapi = async () =>
  fetchFromStrapiRest('counselling-pages', { populate: ['cover', 'seo'], sort: 'order' })
export const fetchAllTestsFromStrapi = async () => fetchFromStrapiRest('test-pages', { populate: 'seo', sort: 'order' })
export const fetchAllVideoLessonsFromStrapi = async () =>
  fetchFromStrapiRest('online-lesson-pages', {
    populate: { lessonInfo: { populate: { lessonAuthor: { populate: 'thumbnail' } } } },
  })
export const fetchAllPagesFromStrapi = async () => fetchFromStrapiRest('pages', { populate: 'seo' })
export const fetchAllCompaniesFromStrapi = async () => fetchFromStrapiRest('company-pages')
export const fetchPageFromStrapi = async (slug: string) => {
  // WARNING: This fetch call is not cached, so it will always make a network request
  // it is for development purposes only and should be removed before merging!!!
  const res = await fetchFromStrapiRest('pages', {
    filters: { slug: { $eq: decodeURIComponent(slug) } },
    populate: DEPRECATED_DEEP,
  })
  return res?.[0]
}
export const fetchAppContentFromStrapi = async (idName: string) => {
  const res = await fetchFromStrapiRest('app-contents', {
    filters: { idName: { $eq: idName } },
    populate: DEPRECATED_DEEP,
  })
  return res[0]?.attributes.content
}
export const fetchCompanyFromStrapi = async (slug: string) => {
  const res = await fetchFromStrapiRest('company-pages', {
    filters: { slug: { $eq: decodeURIComponent(slug) } },
    populate: {
      listGrid: { populate: ['listGridItem'] },
      buttonsList: { populate: ['buttonListItem'] },
      faqSection: { populate: ['entry'] },
    },
  })
  return res[0]?.attributes
}
export const fetchArticleFromStrapi = async (slug: string) => {
  const res = await fetchFromStrapiRest('counselling-pages', {
    filters: { slug: { $eq: decodeURIComponent(slug) } },
    populate: {
      seo: { populate: { metaSocial: { populate: ['image'] }, metaImage: true } },
      cover: true,
      author: { populate: ['thumbnail'] },
      content: { populate: { entry: true, author: { populate: ['thumbnail', 'logo'] } } },
    },
  })
  return res[0]?.attributes
}
export const fetchTestFromStrapi = async (slug: string) => {
  const res = await fetchFromStrapiRest('test-pages', {
    filters: { slug: { $eq: decodeURIComponent(slug) } },
    populate: {
      description: true,
      faq: { populate: { entry: true } },
      seo: { populate: { metaImage: true, metaSocial: true } },
    },
  })
  return res[0]?.attributes
}
export const fetchVideoLessonFromStrapi = async (slug: string) => {
  const res = await fetchFromStrapiRest('online-lesson-pages', {
    filters: { slug: { $eq: decodeURIComponent(slug) } },
    populate: {
      headerImage: true,
      videoSection: { populate: ['chaptersEntry'] },
      lessonInfo: { populate: { lessonAuthor: { populate: ['thumbnail', 'logo'] }, lessonSources: true } },
    },
  })
  return res[0]?.attributes
}

export const fetchHomeArticlesFromStrapi = async () =>
  fetchFromStrapiRest('app', {
    populate: {
      articlesBeforeFirstSession: {
        populate: 'cover',
      },
    },
  })

export const fetchPromoBarFromStrapi = async () =>
  fetchFromStrapiRest(
    'app',
    {
      populate: {
        promotionsBar: {
          populate: 'cover',
        },
      },
    },
    'no-store',
  )

export const fetchAcademyFromStrapi = async () => {
  const res = await fetchFromStrapiRest('academies', {
    populate: {
      content: { populate: { entry: true, thumbnail: { populate: { data: { populate: ['attributes'] } } } } },
    },
  })
  return res[0]
}

export const fetchUseTherapists = async () => {
  const therapists = await fetchFromApi('therapist/list-open')
  return processTherapistList(therapists)
}

export const fetchUseVisiblePremiumContent = async () => {
  const visibleContent = await fetchFromApi('premium-content/teasers')
  return visibleContent
}

export const fetchUseServices = async () => fetchFromApi('service')

export const fetchUseSpecializations = async () => {
  const res = await fetchFromApi('therapist/specializations')
  return res
}

export const fetchReviews = async (page: number) => {
  const res = await fetchFromApi(`review/list?page=${page}`)
  return res
}

export const fetchMenu = async (slugPrefix: string) => {
  const resMenus = await fetchFromStrapiRest('menus')
  const menuId = resMenus.find((m) => m.attributes.slug === `${slugPrefix}-${LOCALE}`)?.id
  if (!menuId) return []
  const res = await fetchFromStrapiRest(`menus/${menuId}`, {
    populate: { items: { populate: { page_relation_one: true } } },
    nested: true,
  })
  return res?.attributes.items.data || []
}

export const fetchMainMenu = async () => fetchMenu('main-menu')
export const fetchOtherMenu = async () => fetchMenu('other-menu')

export type MenuType = {
  id: number
  attributes: {
    title: string
    url?: string
    special_function: 'none' | 'therapists' | 'other'
    target?: string
    page_relation_one: { data?: { attributes: { slug: string } } }
    children?: { data: MenuType[] }
  }
}

export type SlugLocalisations = Record<string, Record<Locale, string>>
export const fetchLocalisedSlugsForType = async (path: string) => {
  const res: {
    id: number
    attributes: { slug: string; localizations: { data: { attributes: { locale: string; slug: string } }[] } }
  }[] = await fetchFromStrapiRest(
    path,
    {
      populate: { localizations: { fields: ['slug', 'locale'] } },
      fields: ['slug'],
    },
    'force-cache',
  )

  return res.reduce((acc, item) => {
    acc[item.attributes.slug] = item.attributes.localizations.data.reduce(
      (acc, loc) => {
        acc[loc.attributes.locale] = loc.attributes.slug
        return acc
      },
      {} as Record<Locale, string>,
    )
    return acc
  }, {} as SlugLocalisations)
}

type ImageDimensions = { w: number; h: number }

export const fetchAllImageDimensions = async () => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/upload/files`)
  const json = await res.json()

  const dimensionsByUrl: { [url: string]: ImageDimensions } = {}

  json.forEach((imgData) => {
    dimensionsByUrl[imgData.url] = { w: imgData.width, h: imgData.height }
  })

  return dimensionsByUrl
}

export const fetchSingleImageDimensions = async (src: string): Promise<ImageDimensions | null> => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/upload/files?_url=${src}`)
  const json = await res.json()
  const file = json[0]

  return file ? { w: file.width, h: file.height } : null
}
